import { useI18nSetLocale } from '~/composables/useI18nSetLocale';
import { getAvatarSrc, getInitials } from '~/composables/getAvatarSrc';
import accountCancellation from '~/data/account-cancellation';

export const useUserStore = defineStore('userStore', {
  state: (): UserState => ({
    profile: {
      id: '',
      trashboardId: '',
      email: '',
      firstName: '',
      lastName: '',
      position: '',
      phoneNumber: {
        national: '',
        country: '',
      },
      language: '',
      attributes: [],
      roles: [],
    },
    password: {
      modals: {
        update: false,
      },
    },
    twoFactorAuthentication: {
      enabled: false,
      qrCodeSvg: '',
      qrCodeLoaded: false,
      otpCode: '',
      loading: false,
      step: 1,
      error: '',
      recoveryCodes: [],
      recoveryCodesLoaded: false,
      modals: {
        enable: false,
        disable: false,
      },
    },
    showAccountCancellationModal: false,
    showChangeEmailModal: false,
  }),
  actions: {
    setUserId(id: string) {
      this.profile.id = id;
    },
    async fetchUserData() {
      await $api<UserDataResponse>(`/api/v1/users/${this.userId}`)
        .then(async (data) => {
          await this.setUserData(data);
          return data;
        })
        .catch((error) => {
          useErrorNotify(error.data);
        });
    },
    updateUserData(profileForm: UserProfileForm) {
      return $api<UserDataResponse>(`/api/v1/users/${this.userId}`, {
        method: 'PATCH',
        body: {
          first_name: profileForm.firstName ?? '',
          last_name: profileForm.lastName ?? '',
          position: profileForm.position ?? '',
          phone_number: {
            country: profileForm.phoneNumberCountry ?? 'DE',
            national: profileForm.phoneNumberNational ?? '',
          },
          language: profileForm.language,
        },
      }).then(async (data) => {
        await this.setUserData(data);
        useNotify(
          'success',
          'user.profile.settings.update.success.title',
          'user.profile.settings.update.success.description',
        );
        return data;
      });
    },
    async setUserData(data: UserDataResponse) {
      this.profile = {
        id: data.data.id,
        trashboardId: data.data.trashboardId,
        email: data.data.email,
        firstName: data.data.firstName ?? '',
        lastName: data.data.lastName ?? '',
        position: data.data.position ?? '',
        phoneNumber: {
          national: data.data.phoneNumber?.national ?? '',
          country: data.data.phoneNumber?.country ?? 'DE',
        },
        language: data.data.language ?? 'en',
        attributes: data.data.attributes,
        roles: data.data.roles,
      };

      this.twoFactorAuthentication.enabled = data.data.twoFactorEnabled as boolean;

      if (!['en', 'de'].includes(this.profile.language as string)) {
        this.profile.language = 'en';
      }

      await useI18nSetLocale(this.profile.language as string);
    },
    updatePassword(currentPassword: string, newPassword: string) {
      return $api(`/api/v1/users/${this.userId}/update-password`, {
        method: 'PATCH',
        body: {
          password: currentPassword,
          new_password: newPassword,
        },
      });
    },
    async enableTwoFactorAuthentication() {
      await $api(`/api/v1/users/${this.userId}/2fa`, {
        method: 'POST',
      }).catch((error) => {
        useErrorNotify(error.data);
      });
    },
    async getTwoFactorAuthenticationQrCode() {
      this.twoFactorAuthentication.qrCodeLoaded = false;
      await $api<TwoFactorAuthenticationQrCodeResponse>(`/api/v1/users/${this.userId}/2fa/qr-code`)
        .then((data) => {
          this.twoFactorAuthentication.qrCodeSvg = data.svg;
          this.twoFactorAuthentication.qrCodeLoaded = true;
          return data;
        })
        .catch((error) => {
          useErrorNotify(error.data);
        });
    },
    async confirmTwoFactorCode() {
      this.twoFactorAuthentication.loading = true;
      await $api(`/api/v1/users/${this.userId}/2fa/confirm`, {
        method: 'POST',
        body: { code: this.twoFactorAuthentication.otpCode },
      })
        .then((data) => {
          this.twoFactorAuthentication.step = 2;
          return data;
        })
        .catch((error) => {
          this.twoFactorAuthentication.error = error.data.errors.exception[0].message;
        })
        .finally(() => {
          this.twoFactorAuthentication.loading = false;
        });
    },
    async getRecoveryCodes() {
      this.twoFactorAuthentication.recoveryCodesLoaded = false;
      await $api(`/api/v1/users/${this.userId}/2fa/recovery-codes`)
        .then((data) => {
          this.twoFactorAuthentication.recoveryCodesLoaded = true;
          this.twoFactorAuthentication.recoveryCodes = data as string[];
          return data;
        })
        .catch((error) => {
          useErrorNotify(error.data);
        });
    },
    async deactivateTwoFactorAuthentication() {
      await $api(`/api/v1/users/${this.userId}/2fa/${this.twoFactorAuthentication.otpCode}`, {
        method: 'DELETE',
      })
        .then((data) => {
          this.twoFactorAuthentication.enabled = false;
          return data;
        })
        .catch((error) => {
          useErrorNotify(error.data);
        });
    },
    checkAccountDeletion() {
      // TODO: when API-Gateway has a route to check, we need to use that
      return accountCancellation[2]; // 0 = unpaid invoices, 1 = active subscriptions, 2 = demoboxes, 3 = standard
    },
  },
  getters: {
    userId: (state) => state.profile.id,
    name: (state) => `${state.profile.firstName} ${state.profile.lastName}`,
    initials: (state) => getInitials(state.profile.firstName, state.profile.lastName),
    userObject: (state) => state.profile,
    gravatarUrl: (state) => getAvatarSrc(state.profile.email),
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
