import destr from 'destr';
import defu from 'defu';
import { type FetchOptions } from 'ofetch';
import type { Dayjs } from 'dayjs';
import type { NestedObject } from '~/composables/useChangeCase';
import type { UseFetchOptions } from '#app';

async function checkAuthorization(checkToken = true) {
  const authStore = useAuthStore();

  if (authStore.isLoggedIn && checkToken) {
    const dayjs = useDayjs();
    const expireDate = authStore.getTokenExpireDate as Dayjs;
    const currentDate = dayjs();

    const minutes = expireDate.diff(currentDate, 'minute');
    if (minutes < 5) {
      await authStore.refreshToken();
    }
  }
}

export async function useCustomFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
  checkToken = true,
) {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  await checkAuthorization(checkToken);

  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.gatewayBaseUrl,
    headers: authStore.isLoggedIn
      ? { Accept: 'application/json', Authorization: `Bearer ${authStore.getToken}` }
      : {},
    transform: (data) => useChangeKeys(data as NestedObject) as T,
  };

  const params = defu(defaults, options);

  return useFetch(url, params);
}

async function setupFetch(options: FetchOptions = {}, checkToken = true) {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  await checkAuthorization(checkToken);

  const defaults: FetchOptions = {
    baseURL: config.public.gatewayBaseUrl,
    headers: authStore.isLoggedIn
      ? {
          Accept: 'application/json',
          Authorization: `Bearer ${authStore.getToken}`,
        }
      : {},
    parseResponse: (responseText) => useChangeKeys(destr(responseText)),
    async onResponseError({ response }) {
      if (response.status === 401) {
        const nuxtConfig = useRuntimeConfig();
        await navigateTo(`${nuxtConfig.public.oldDashboardUrl}${nuxtConfig.public.loginPageUrl}`, {
          external: true,
        });
      }
    },
  };

  return defu(defaults, options);
}

export async function $apiRaw<T>(url: string, options: FetchOptions = {}, checkToken = true) {
  const params = await setupFetch(options, checkToken);

  const rawParams = {
    baseURL: params.baseURL,
    headers: params.headers,
    parseResponse: params.parseResponse,
    onResponseError: params.onResponseError,
  };

  return $fetch.raw<T>(url, rawParams);
}

export async function $api<T>(url: string, options: FetchOptions = {}, checkToken = true) {
  const params = await setupFetch(options, checkToken);

  const customFetch = $fetch.create<T>(params);

  return customFetch<T>(url);
}
