import type { RouteLocationNormalizedGeneric } from 'vue-router';

const unprotectedRoutesNames = ['verification-email'];

const isUnprotectedRoute = (route: RouteLocationNormalizedGeneric): boolean =>
  unprotectedRoutesNames.includes(route.name as string);

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  const config = useRuntimeConfig();
  await authStore.checkForToken(to.query);

  if (!authStore.isLoggedIn) {
    if (!isUnprotectedRoute(to)) {
      await navigateTo(`${config.public.oldDashboardUrl}${config.public.loginPageUrl}`, {
        external: true,
      });
    }
  } else if (authStore.getUserId === '') {
    await authStore.parseToken();
  }
});
