export const features: FeatureMap = {
  BoxPages: ['SUPER_ADMIN'],
  PaymentSettings: ['INTERNAL_FOCUS_GROUP'],
};

export const routesFeatureMap: FeatureRoutesMap = {
  '/boxes': 'BoxPages',
  '/payment-methods': 'PaymentSettings',
  '/boxes/create-new': 'BoxPages',
  '/boxes/create-new/general': 'BoxPages',
  '/boxes/create-new/select-plan': 'BoxPages',
  '/boxes/create-new/summary': 'BoxPages',
  '/boxes/create-new/wordpress': 'BoxPages',
};
